import React, { useState } from "react"
import PropTypes from "prop-types"
import html2canvas from "html2canvas"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { Box, Button, Icon, Menu, MenuItem } from "@mui/material"
import {
  useProductGroupExport,
  useProductGroupFilter,
  useProductGroupsOutline,
} from "./hooks"
import { DXSnackbar } from "../ui"
import { proxyURL } from "../../util/env"

function AssortmentAnalysisExport({ ...props }) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [exporting, setExporting] = useState(false)
  const [snackbarContent, setSnackbarContent] = useState(null)
  const { categoryExport, exportCategory } = useProductGroupExport()
  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()
  const [productGroupId, _, all] = useProductGroupFilter()
  const isAll = all === "true"
  let disableCSV = false

  if (
    isIdle ||
    isFetching ||
    isError ||
    !productGroups ||
    (productGroups && productGroups.length === 0)
  ) {
    disableCSV = true
  }

  const productGroup =
    productGroups?.find((opt) => opt.id === productGroupId) || productGroups[0]

  let { id: groupId } = productGroup

  if (isAll) {
    groupId = undefined
  }

  const csvSnackbar = (
    <Box alignItems="center" display="flex" justifyContent="start" width="100%">
      <Icon
        className="material-symbols-outlined"
        sx={{
          mr: 1,
        }}
      >
        ios_share
      </Icon>
      {t("Exporting .CSV")}
    </Box>
  )

  const pngSnackbar = (
    <Box alignItems="center" display="flex" justifyContent="start" width="100%">
      <Icon
        className="material-symbols-outlined"
        sx={{
          mr: 1,
        }}
      >
        ios_share
      </Icon>
      {t("Exporting .PNG")}
    </Box>
  )

  const handleOpenMenu = async (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleExportPNGClick = async () => {
    setSnackbarContent(pngSnackbar)
    setExporting(true)

    const element = document.querySelector(".aatoolCaptureDiv")
    element.scrollTo(0, 0)

    try {
      const canvas = await html2canvas(element, {
        imageTimeout: 300000,
        windowWidth: element.scrollWidth + 100,
        windowHeight: element.scrollHeight + 200,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        proxy: proxyURL,
      })

      const link = document.createElement("a")
      link.download = "Assortment Analysis.png"
      link.href = canvas.toDataURL()
      link.click()
      setExporting(false)
      mixpanel.track("A3Tool - Export Image")
    } catch (e) {
      setExporting(false)
      throw e
    }
  }

  const handleExportCSVClick = (e) => {
    e.preventDefault()
    setSnackbarContent(csvSnackbar)
    const cat = isAll ? productGroupId : 0
    exportCategory(cat, groupId)
    mixpanel.track("A3Tool - Export Category", { category: cat, groupId })
    e.stopPropagation()
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button
          {...props}
          size="small"
          onClick={handleOpenMenu}
          color="primary"
          oepn={open}
          startIcon={<Icon className="material-symbols-outlined">ios_share</Icon>}
          data-testid="assortment-export-button"
        >
          {t("Export")}
        </Button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            data-testid="assortment-export-category"
            disabled={disableCSV || categoryExport.loading}
            onClick={handleExportCSVClick}
          >
            {t("Export Excel")}
          </MenuItem>
          <MenuItem
            disabled={exporting}
            data-testid="assortment-export-image"
            onClick={handleExportPNGClick}
          >
            {t("Export PNG")}
          </MenuItem>
        </Menu>
      </Box>
      <DXSnackbar autoHideDuration={3000} open={exporting || categoryExport.loading}>
        {snackbarContent}
      </DXSnackbar>
    </>
  )
}

AssortmentAnalysisExport.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
}

AssortmentAnalysisExport.defaultProps = {
  variant: "text",
}

export { AssortmentAnalysisExport }
