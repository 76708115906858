/* -------------------------------------------------------------------------- */
/*                                    async                                   */
/* -------------------------------------------------------------------------- */

import { errorType, successType } from "../../../util/redux-observable-helpers"

export const ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT =
  "@assortment-analysis-page/ENQUEUE_GET_CUSTOMER_ASSORTMENT"

export const ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS =
  "@assortment-analysis-page/ENQUEUE_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS"

export const ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT_OUTLINE =
  "@assortment-analysis-page/ENQUEUE_GET_CUSTOMER_ASSORTMENT_OUTLINE"

/* -------------------------------------------------------------------------- */
/*                                  fetchers                                  */
/* -------------------------------------------------------------------------- */

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT =
  "@assortment-analysis-page/GET_CUSTOMER_ASSORTMENT"

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_ERROR = errorType(
  ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT,
)

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_SUCCESS = successType(
  ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT,
)

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS =
  "@assortment-analysis-page/GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS"

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS_ERROR =
  errorType(ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS)

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS_SUCCESS =
  successType(ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS)

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE =
  "@assortment-analysis-page/GET_CUSTOMER_ASSORTMENT_OUTLINE"

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE_ERROR = errorType(
  ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE,
)

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE_SUCCESS = successType(
  ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE,
)

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT =
  "@assortment-analysis-page/GET_CUSTOMER_ASSORTMENT_EXPORT"

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT_ERROR = errorType(
  ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT,
)

export const ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT_SUCCESS = successType(
  ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT,
)

/* -------------------------------------------------------------------------- */
/*                                  mutations                                 */
/* -------------------------------------------------------------------------- */

export const ASSORTMENT_ANALYSIS_SET_DIMENSION_FILTER =
  "@assortment-analysis-page/SET_DIMENSION_FILTER"

export const ASSORTMENT_ANALYSIS_SET_DIMENSION_SORT =
  "@assortment-analysis-page/SET_DIMENSION_SORT"

export const ASSORTMENT_ANALYSIS_SET_FILTER_OPTIONS =
  "@assortment-analysis-page/SET_FILTER_OPTIONS"

export const ASSORTMENT_ANALYSIS_SET_PRODUCT_GROUP_FILTER =
  "@assortment-analysis-page/SET_PRODUCT_GROUP_FILTER"

export const ASSORTMENT_ANALYSIS_SET_PAGE = "@assortment-analysis-page/SET_PAGE"

export const ASSORTMENT_ANALYSIS_SET_PAGE_SIZE =
  "@assortment-analysis-page/SET_PAGE_SIZE"

export const ASSORTMENT_ANALYSIS_SET_STEP_FILTER = "@assortment-analysis-page/SET_STEP"

export const ASSORTMENT_ANALYSIS_SET_THRESHOLD_FILTER =
  "@assortment-analysis-page/SET_THRESHOLD"

export const ASSORTMENT_ANALYSIS_SET_MODE = "@assortment-analysis-page/SET_MODE"

export const ASSORTMENT_ANALYSIS_SET_HIGHLIGHT =
  "@assortment-analysis-page/SET_HIGHLIGHT"

export const ASSORTMENT_ANALYSIS_SET_DATA_FILTER =
  "@assortment-analysis-page/SET_DATA_FILTER"

export const ASSORTMENT_ANALYSIS_SET_FILTER_SHOPS =
  "@assortment-analysis-page/SET_FILTER_SHOPS"

/* -------------------------------------------------------------------------- */
/*                                    state                                   */
/* -------------------------------------------------------------------------- */

export const ASSORTMENT_ANALYSIS_REHYDRATE_FROM_PREVIOUS_STATE =
  "@assortment-analysis-page/REHYDRATE_FROM_PREVIOUS_STATE"

export const ASSORTMENT_ANALISYS_READ_URL_STATE =
  "@assortment-analysis-page/READ_URL_STATE"

/* -------------------------------------------------------------------------- */
/*                                  triggers                                  */
/* -------------------------------------------------------------------------- */

export const ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT =
  "@assortment-analysis-page/TRIGGER_GET_CUSTOMER_ASSORTMENT"

export const ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS =
  "@assortment-analysis-page/TRIGGER_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS"

export const ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT_OUTLINE =
  "@assortment-analysis-page/TRIGGER_GET_CUSTOMER_ASSORTMENT_OUTLINE"
