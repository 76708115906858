/* eslint-disable react/forbid-prop-types */
import Pagination from "material-ui-flat-pagination"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import clsx from "clsx"
import styles from "./TableRoot.module.scss"
import { Table } from "."
import { isDevelopment } from "../../../util/env"
import { computeVirtualRowFillerPaddings } from "../../../util/virtualization"
import useCustomer from "../../../hooks/use-customer"

const debugTable = isDevelopment

function TableRoot({
  className,
  columns,
  data,
  justifyContent,
  pageCount,
  paginationState,
  paginationStateSetter,
  pinnedColumns,
  pinnedRows,
  resultsCount,
  stickyXHeader,
  stickyYHeader,
  xHeaderId,
  overscan,
  cellPad,
  rowStyle,
  fullwidth,
  ...props
}) {
  const customer = useCustomer()
  const { code } = customer
  const sessionKey = `${code}-session-pinned-columns`

  const [expanded, setExpanded] = useState({})
  const [columnPinning, setColumnPinning] = useState({
    left: [...pinnedColumns],
    right: [],
  })
  const [rowPinning, setRowPinning] = useState({
    top: [],
    bottom: [...pinnedRows],
  })
  const containerRef = useRef(null)
  const isControlledPagination = !!paginationState && !!paginationStateSetter
  const getCommonPinningStyles = (column, rowIsPinned) => {
    const isPinned = column.getIsPinned()

    const bg = rowIsPinned ? "rgba(0, 0, 0, 0.56) !important" : undefined

    return {
      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
      opacity: isPinned && !rowIsPinned ? 0.96 : 1,
      position: isPinned ? "sticky" : "relative",
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      backdropFilter: isPinned || rowIsPinned ? `blur(16px)` : undefined,
      backgroundColor: bg,
    }
  }
  const validRowPinning = () =>
    rowPinning.bottom.every((row) => Number(row) < data.length)

  const getRowPinningStyles = (row, table) => {
    if (!validRowPinning()) {
      return {}
    }

    const isPinned = row.getIsPinned()

    return {
      position: "sticky",
      top: isPinned === "top" ? `${row.getPinnedIndex() * 56 + 48}px` : undefined,
      bottom:
        isPinned === "bottom"
          ? `${(table.getBottomRows().length - 1 - row.getPinnedIndex()) * 58}px`
          : undefined,
    }
  }

  useEffect(() => {
    const isNotSame = (session) =>
      session && JSON.stringify(session) !== JSON.stringify(columnPinning)

    const pinnedSession = JSON.parse(window.sessionStorage.getItem(sessionKey))
    if (pinnedSession && isNotSame(pinnedSession)) {
      setColumnPinning(pinnedSession)
    }
  }, [columnPinning, sessionKey])

  const table = useReactTable({
    columns,
    data,
    debugTable,
    enableColumnPinning: true,
    enableRowPinning: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    onColumnPinningChange: (p) =>
      setColumnPinning((prev) => {
        const newVal = p(prev)
        try {
          window.sessionStorage.setItem(sessionKey, JSON.stringify(newVal))
        } catch (error) {
          console.error("Failed to update session storage:", error)
        }
        return newVal
      }),
    onRowPinningChange: setRowPinning,
    ...(isControlledPagination
      ? {
          pageCount,
          manualPagination: true,
          onPaginationChange: paginationStateSetter,
          state: { columnPinning, expanded, pagination: paginationState, rowPinning },
        }
      : {
          state: { columnPinning, expanded, rowPinning },
        }),
  })

  const { rows } = table.getRowModel()

  /**
   * When cells have one item they have 144px in height
   * When cells have multiple items they have 170px in height.
   * When hovered they have slightly more (due to the rendered button)
   *
   * Tanstack recommends that estimateSize is the largest possible (predictable height)
   *
   * @see https://tanstack.com/virtual/v3/docs/api/virtualizer
   */
  const virtualizer = useVirtualizer({
    count: rows.length,
    debug: debugTable,
    overscan,
    horizontal: false,
    estimateSize: () => 170,
    getScrollElement: () => containerRef.current,
  })

  const totalSize = virtualizer.getTotalSize()
  const virtualRows = virtualizer.getVirtualItems()

  const [paddingTop, paddingBottom] = computeVirtualRowFillerPaddings(
    totalSize,
    virtualRows,
  )

  return (
    <Table.Wrapper justifyContent={justifyContent} {...props}>
      <Table.Container className={className} ref={containerRef}>
        <Table.Element className={clsx(fullwidth && styles.fullwidth)}>
          <thead className={styles.head}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={clsx(styles.row, {
                  [styles.ybackdrop]: stickyYHeader,
                  [styles.ysticky]: stickyYHeader,
                })}
              >
                {headerGroup.headers.map(({ isPlaceholder, ...header }) => {
                  const HeaderComponent = isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())

                  return (
                    <th
                      key={header.id}
                      className={clsx(styles["column-header"])}
                      style={getCommonPinningStyles(header.column)}
                    >
                      {HeaderComponent}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingBottom > 0 && <Table.Pad height={paddingTop} />}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index]

              return (
                <tr
                  key={row.id}
                  className={clsx(styles.row, {
                    [styles[rowStyle]]: row.getIsPinned(),
                  })}
                  style={getRowPinningStyles(row, table)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const isSticky = stickyXHeader && cell.column.id === xHeaderId

                    return (
                      <td
                        key={cell.id}
                        className={clsx(styles.cell, {
                          [styles.xbackdrop]: isSticky,
                          [styles.xsticky]: isSticky,
                          [styles.cellpad]: cellPad,
                        })}
                        style={getCommonPinningStyles(cell.column, row.getIsPinned())}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {paddingBottom > 0 && <Table.Pad height={paddingBottom} />}
          </tbody>
        </Table.Element>
      </Table.Container>
      {pageCount > 0 && (
        <div className={clsx(styles.pagination, "pagination")}>
          <Pagination
            classes={{
              root: "dx-pagination",
              text: "dx-page-item",
              rootCurrent: "dx-active-page",
              disabled: "dx-inactive-page",
            }}
            limit={paginationState.pageSize}
            offset={paginationState.pageIndex * paginationState.pageSize}
            page={paginationState.pageIndex + 1}
            total={resultsCount}
            onClick={(_event, _offset, page) => table.setPageIndex(page - 1)}
            size="large"
            innerButtonCount={3}
            outerButtonCount={3}
          />
        </div>
      )}
    </Table.Wrapper>
  )
}

TableRoot.propTypes = {
  justifyContent: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pageCount: PropTypes.number,
  paginationState: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }),
  paginationStateSetter: PropTypes.func,
  pinnedColumns: PropTypes.array,
  pinnedRows: PropTypes.array,
  resultsCount: PropTypes.number,
  stickyXHeader: PropTypes.bool,
  stickyYHeader: PropTypes.bool,
  xHeaderId: PropTypes.string,
  overscan: PropTypes.number,
  cellPad: PropTypes.bool,
  rowStyle: PropTypes.string,
  fullwidth: PropTypes.bool,
}

TableRoot.defaultProps = {
  justifyContent: "center",
  className: "",
  pageCount: -1,
  paginationState: undefined,
  paginationStateSetter: undefined,
  pinnedColumns: [],
  pinnedRows: [],
  resultsCount: -1,
  stickyXHeader: false,
  stickyYHeader: false,
  xHeaderId: "",
  overscan: 50,
  cellPad: true,
  rowStyle: "",
  fullwidth: false,
}

export default TableRoot
