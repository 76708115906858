import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import resources from "./translations"
import { KEY_INTERNAL_USER, getValue } from "./util/local-storage"

const INTERNAL_USER = getValue(KEY_INTERNAL_USER)
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: INTERNAL_USER || false,
    fallbackLng: "en",
  })

export default i18n
