import CloseIcon from "@mui/icons-material/Close"
import { TextField, inputBaseClasses } from "@mui/material"
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Divider from "@mui/material/Divider"
import Fade from "@mui/material/Fade"
import FormControlLabel, {
  formControlLabelClasses,
} from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { bool, func } from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  EMPTY_FILTERS,
  PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY,
  PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS,
} from "../../../../ducks/widgets/product-search/constants"
import { useProductSearch } from "../../../../ducks/widgets/product-search/hooks"
import { getCountryName } from "../../../../util/intl"
import { matchStatusWithUnmatched as statusOptions } from "../../../../util/match-status"
import { matchTypOptionsList as matchTypeOptions } from "../../../../util/match-type"
import { MatchStatusIcon } from "../../../match-status-icon/MatchStatusIcon"
import MatchTypeIcon from "../../../match-type-icon"
import { CounterChip } from "../../../ui/CounterChip/CounterChip"

import { listMatchSources } from "../../../../ducks/matches/action-creators"
import useShops from "../../../../hooks/use-shops"
import { getMatchSourceFilterOptions } from "../../../../util/match-source"
import { matchProductAvailabilityOptionsList as availabilityOptions } from "../../../../util/product-availability"
import { getBestProductContentV1 } from "../../../../util/volumes"
import { DXIcon } from "../../../dx-icon/DXIcon"
import { NULL } from "../../../../util/constants"
import { isEmpty } from "../../../../util/filter"

function ProductSearchFilterDialog({ open, onClose }) {
  const {
    setFilters,
    state: {
      [PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY]: filterOptions,
      [PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]: defaultFilters,
      [PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY]: refProdSummary,
    },
    referenceProduct,
  } = useProductSearch()
  const theme = useTheme()
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters)
  const [refUnit, setRefUnit] = useState("")
  const { getByCode } = useShops()
  const dispatch = useDispatch()
  const validSources = useSelector(({ matches }) => matches.validSources)
  const matchesPerShop = useMemo(() => refProdSummary?.shops || {}, [refProdSummary])

  useEffect(() => {
    setSelectedFilters(defaultFilters)
  }, [defaultFilters])

  useEffect(() => {
    if (open) {
      return
    }

    setSelectedFilters(defaultFilters)
  }, [defaultFilters, open])

  const countries = useMemo(() => {
    if (!filterOptions.countries) {
      return []
    }

    return Object.keys(filterOptions.countries).map((country) => ({
      label: getCountryName(country),
      value: country,
    }))
  }, [filterOptions.countries])

  const onShowResults = () => {
    setFilters(selectedFilters)
    onClose()
  }

  const onChange = (filterName) => (event) => {
    const { checked, value } = event.target
    const newValue = { ...selectedFilters[filterName], [value]: checked }

    setSelectedFilters({
      ...selectedFilters,
      [filterName]: newValue,
    })
  }

  const updateBrands = (_, brands) => {
    if (!brands) {
      return
    }

    const newBrands = brands.map((brand) => ({
      [brand]: true,
    }))
    setSelectedFilters({
      ...selectedFilters,
      brands: {
        ...Object.assign({}, ...newBrands),
      },
    })
  }

  const retailers = useMemo(() => {
    if (!filterOptions.shops) {
      return []
    }

    const allShopOptions = {
      ...selectedFilters.shops,
      ...matchesPerShop,
      ...filterOptions.shops,
    }

    return Object.keys(allShopOptions)
      .map((key) => ({
        id: key,
        name: getByCode(key).name,
        count: filterOptions.shops[key],
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [filterOptions, getByCode, selectedFilters, matchesPerShop])

  const matchSource = useMemo(
    () => getMatchSourceFilterOptions(validSources),
    [validSources],
  )

  const units = useMemo(() => {
    let unitList = []
    if (!filterOptions.units) {
      return unitList
    }
    unitList = filterOptions.units

    if (referenceProduct?.data?.contents_v1) {
      const bestContent = getBestProductContentV1(referenceProduct.data.contents_v1)
      setRefUnit(bestContent.unit)
      if (!unitList.includes(bestContent.unit)) {
        unitList.push(bestContent.unit)
      }
    }

    return unitList
  }, [filterOptions.units, referenceProduct])

  const brandOptions = useMemo(() => {
    if (!filterOptions.brands) {
      return []
    }

    return Object.keys(filterOptions.brands).sort((a, b) => a.localeCompare(b))
  }, [filterOptions.brands])

  useEffect(() => {
    dispatch(listMatchSources())
  }, [dispatch])

  function brandsToArray(brands) {
    return Object.keys(brands).filter((brand) => brands[brand])
  }

  function clearFilters() {
    setSelectedFilters(EMPTY_FILTERS)
  }

  function clearFactors() {
    setSelectedFilters({
      ...selectedFilters,
      factors: EMPTY_FILTERS.factors,
      minFactor: EMPTY_FILTERS.minFactor,
      maxFactor: EMPTY_FILTERS.maxFactor,
    })
  }

  function clearPrice() {
    setSelectedFilters({
      ...selectedFilters,
      minPrice: EMPTY_FILTERS.minPrice,
      maxPrice: EMPTY_FILTERS.maxPrice,
    })
  }

  function clearContent() {
    setSelectedFilters({
      ...selectedFilters,
      content: EMPTY_FILTERS.content,
    })
  }

  function clearRetailers() {
    setSelectedFilters({
      ...selectedFilters,
      shops: EMPTY_FILTERS.shops,
    })
  }

  function clearCountries() {
    setSelectedFilters({
      ...selectedFilters,
      countries: EMPTY_FILTERS.countries,
    })
  }

  function clearMatchTypes() {
    setSelectedFilters({
      ...selectedFilters,
      matchType: EMPTY_FILTERS.matchType,
    })
  }

  function onChangeInput(filterName) {
    return (event) => {
      const { value } = event.target
      setSelectedFilters({
        ...selectedFilters,
        [filterName]: value,
      })
    }
  }

  const { min: minContent } = selectedFilters.content
  const { max: maxContent } = selectedFilters.content
  const isMinContentInvalid = Number(minContent) > Number(maxContent)
  const isMaxContentInvalid = Number(maxContent) < Number(minContent)

  function onChangeInputContent(filterName) {
    return (event) => {
      const { value } = event.target

      setSelectedFilters({
        ...selectedFilters,
        content: {
          unit: selectedFilters.content.unit || refUnit,
          min: selectedFilters.content.min || 0,
          max: selectedFilters.content.max || 10,
          [filterName]: value,
        },
      })
    }
  }

  function handleUnitChange(event) {
    setSelectedFilters({
      ...selectedFilters,
      content: {
        min: minContent || 0,
        max: maxContent || 10,
        ...selectedFilters.content,
        unit: event.target.value,
      },
    })
  }

  function onChangeHide(filterName) {
    return (event) => {
      const { checked } = event.target

      setSelectedFilters({
        ...selectedFilters,
        [filterName]: checked,
      })
    }
  }

  const { minPrice } = selectedFilters
  const { maxPrice } = selectedFilters
  const isMinPriceInvalid = Number(minPrice) > Number(maxPrice)
  const isMaxPriceInvalid = Number(maxPrice) < Number(minPrice)

  const { minFactor } = selectedFilters
  const { maxFactor } = selectedFilters
  const isMinFactorInvalid = Number(minFactor) > Number(maxFactor)
  const isMaxFactorInvalid = Number(maxFactor) < Number(minFactor)

  const {
    hasContent,
    hasFactors,
    hasPrice,
    hasShops,
    hasCountries,
    hasMatchTypes,
    totalFilters,
  } = useMemo(() => {
    let count = 0
    let content = false
    let factors = false
    let price = false

    const selectableFilters = [
      "status",
      "availabilities",
      "source",
      "shops",
      "countries",
      "matchType",
    ]

    const hasChecked = {}

    selectableFilters.forEach((key) => {
      const checks = Object.values(selectedFilters[key])
      checks.forEach((isChecked) => {
        if (isChecked) {
          hasChecked[key] = true
        }
        count += isChecked ? 1 : 0
      })
    })

    const brands = Object.values(selectedFilters.brands)
    if (brands && brands.length > 0) {
      count++
    }

    if (selectedFilters.maxPrice || selectedFilters.minPrice) {
      price = true
      count++
    }

    if (selectedFilters.minFactor || selectedFilters.maxFactor) {
      factors = true
      count++
    }

    if (
      !isEmpty(selectedFilters.content.min) ||
      !isEmpty(selectedFilters.content.max)
    ) {
      content = true
      count++
    }

    if (selectedFilters.onlyWithPrice) {
      count++
    }

    if (selectedFilters.onlyWithContent) {
      count++
    }

    if (selectedFilters.onlyWithMF) {
      count++
    }

    return {
      hasContent: content,
      hasFactors: factors,
      hasPrice: price,
      hasShops: hasChecked.shops,
      hasCountries: hasChecked.countries,
      hasMatchTypes: hasChecked.matchType,
      totalFilters: count,
    }
  }, [selectedFilters])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Product filters
          <IconButton onClick={onClose} aria-label="close" size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            [`.${formControlLabelClasses.root}.w-160`]: {
              width: 160,
            },
            [`.${inputBaseClasses.root}`]: {
              width: 136,
            },
            [`.${autocompleteClasses.inputRoot}`]: {
              width: "100%",
            },
            ".clear-button": {
              fontSize: " 0.75rem",
              fontWeight: 600,
              lineHeight: "1rem",
            },
          }}
          display="flex"
          flexDirection="column"
          mb={4}
          gap={2}
        >
          <Typography variant="subtitle1">
            Product match status with reference product
          </Typography>
          <FormGroup row>
            {statusOptions.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                control={<Checkbox color="default" size="small" />}
                value={value}
                onChange={onChange("status")}
                checked={selectedFilters.status[value] || false}
                className="w-160"
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    {label}
                    <MatchStatusIcon status={value} />
                  </Box>
                }
              />
            ))}
          </FormGroup>

          <Divider />

          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">Product match type</Typography>
            <Fade in={hasMatchTypes}>
              <Button
                onClick={clearMatchTypes}
                variant="underlined"
                size="small"
                className="clear-button"
                disableRipple
              >
                Clear match types
              </Button>
            </Fade>
          </Box>
          <FormGroup row className="w-160">
            {matchTypeOptions.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                control={<Checkbox color="default" size="small" />}
                value={value}
                onChange={onChange("matchType")}
                checked={selectedFilters.matchType[value] || false}
                className="w-200"
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography
                      noWrap
                      textOverflow="ellipsis"
                      maxWidth={185}
                      title={label}
                    >
                      {label}
                    </Typography>
                    <MatchTypeIcon type={value} />
                  </Box>
                }
              />
            ))}
          </FormGroup>

          <Divider />

          <Typography variant="subtitle1">Product availability</Typography>
          <FormGroup row>
            {availabilityOptions.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                onChange={onChange("availabilities")}
                checked={selectedFilters.availabilities[value] || false}
                className="w-160"
                control={<Checkbox color="default" size="small" />}
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    {label}
                    <DXIcon type={value} />
                  </Box>
                }
              />
            ))}
          </FormGroup>

          <Divider />

          <Typography variant="subtitle1">Match source</Typography>
          <FormGroup row>
            {matchSource.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Checkbox color="default" size="small" />}
                onChange={onChange("source")}
                checked={selectedFilters.source[value] || false}
                label={label}
              />
            ))}
          </FormGroup>

          <Divider />

          {countries.length > 1 && (
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subtitle1">Country</Typography>
                <Fade in={hasCountries}>
                  <Button
                    onClick={clearCountries}
                    variant="underlined"
                    size="small"
                    className="clear-button"
                    disableRipple
                  >
                    Clear countries
                  </Button>
                </Fade>
              </Box>
              <FormGroup row>
                {countries.map(({ label, value }) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Checkbox color="default" size="small" />}
                    onChange={onChange("countries")}
                    checked={selectedFilters.countries[value] || false}
                    className="w-160"
                    label={label}
                  />
                ))}
              </FormGroup>
              <Divider />
            </>
          )}
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">Retailer</Typography>
            <Fade in={hasShops}>
              <Button
                onClick={clearRetailers}
                variant="underlined"
                size="small"
                className="clear-button"
                disableRipple
              >
                Clear retailers
              </Button>
            </Fade>
          </Box>
          <FormGroup row>
            {retailers.map((retailer) => (
              <FormControlLabel
                key={retailer.name}
                control={<Checkbox color="default" size="small" />}
                value={retailer.id}
                onChange={onChange("shops")}
                checked={selectedFilters.shops[retailer.id] || false}
                className="w-160"
                label={
                  <Box display="flex" gap={1}>
                    <Typography
                      noWrap
                      textOverflow="ellipsis"
                      maxWidth={75}
                      title={retailer.name.length > 10 ? retailer.name : undefined}
                    >
                      {retailer.name}
                    </Typography>
                    <CounterChip label={matchesPerShop[retailer.id]} />
                  </Box>
                }
              />
            ))}
          </FormGroup>

          <Divider />

          <Typography variant="subtitle1">Brands</Typography>
          <Box display="flex" alignItems="center">
            <Autocomplete
              defaultValue={brandsToArray(defaultFilters.brands)}
              id="brands"
              filterSelectedOptions
              fullWidth
              getOptionLabel={(option) => (option === NULL ? "Unknown" : option)}
              ListboxProps={{
                sx: { textTransform: "capitalize" },
              }}
              multiple
              onChange={updateBrands}
              options={brandOptions}
              size="small"
              sx={{ textTransform: "capitalize" }}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Brands" placeholder="Brands" />
              )}
            />
          </Box>

          <Divider />

          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">
              Multiplication factor (MF) range
            </Typography>

            <Fade in={hasFactors}>
              <Button
                onClick={clearFactors}
                variant="underlined"
                size="small"
                className="clear-button"
                disableRipple
              >
                Clear MF range
              </Button>
            </Fade>
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label="Minimum"
              value={minFactor || ""}
              placeholder="0"
              onChange={onChangeInput("minFactor")}
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
              helperText={isMinFactorInvalid ? "Invalid min. MF" : undefined}
              error={isMinFactorInvalid}
            />
            <Divider variant="middle" orientation="horizontal" sx={{ width: 32 }} />
            <TextField
              size="small"
              label="Maximum"
              value={maxFactor || ""}
              placeholder="999"
              onChange={onChangeInput("maxFactor")}
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
              helperText={isMaxFactorInvalid ? "Invalid max. MF" : undefined}
              error={isMaxFactorInvalid}
            />
          </Box>
          <FormControlLabel
            checked={selectedFilters.onlyWithMF}
            onChange={onChangeHide("onlyWithMF")}
            control={<Checkbox color="default" size="small" />}
            label="Hide products with no MF set"
          />

          <Divider />

          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">Price range</Typography>

            <Fade in={hasPrice}>
              <Button
                onClick={clearPrice}
                variant="underlined"
                size="small"
                className="clear-button"
                disableRipple
              >
                Clear price range
              </Button>
            </Fade>
          </Box>

          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label="Minimum"
              value={minPrice || ""}
              placeholder="0"
              onChange={onChangeInput("minPrice")}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: "€",
                inputProps: { min: 0 },
              }}
              helperText={isMinPriceInvalid ? "Invalid min. price" : undefined}
              error={isMinPriceInvalid}
            />
            <Divider variant="middle" orientation="horizontal" sx={{ width: 32 }} />
            <TextField
              size="small"
              label="Maximum"
              value={maxPrice || ""}
              placeholder={filterOptions.maxPrice}
              onChange={onChangeInput("maxPrice")}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: "€",
                inputProps: { min: 0 },
              }}
              helperText={isMaxPriceInvalid ? "Invalid max. price" : undefined}
              error={isMaxPriceInvalid}
            />
          </Box>
          <FormControlLabel
            checked={selectedFilters.onlyWithPrice}
            onChange={onChangeHide("onlyWithPrice")}
            control={<Checkbox color="default" size="small" />}
            label="Hide products with no price set"
          />

          <Divider />

          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">Content range</Typography>
            <Fade in={hasContent}>
              <Button
                onClick={clearContent}
                variant="underlined"
                size="small"
                className="clear-button"
                disableRipple
              >
                Clear content range
              </Button>
            </Fade>
          </Box>
          <TextField
            value={selectedFilters.content.unit || refUnit}
            label="Unit"
            onChange={handleUnitChange}
            select
            size="small"
            sx={{
              textAlign: "left",
            }}
          >
            {units.map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </TextField>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label="Minimum"
              value={minContent || ""}
              placeholder="0"
              onChange={onChangeInputContent("min")}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: selectedFilters.content.unit || refUnit,
                inputProps: { min: 0 },
              }}
              helperText={isMinContentInvalid ? "Invalid min. content" : undefined}
              error={isMinContentInvalid}
            />
            <Divider variant="middle" orientation="horizontal" sx={{ width: 32 }} />
            <TextField
              size="small"
              label="Maximum"
              value={maxContent || ""}
              placeholder="10"
              onChange={onChangeInputContent("max")}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: selectedFilters.content?.unit || refUnit,
                inputProps: { min: 0 },
              }}
              helperText={isMaxContentInvalid ? "Invalid max. content" : undefined}
              error={isMaxContentInvalid}
            />
          </Box>
          <FormControlLabel
            checked={selectedFilters.onlyWithContent}
            onChange={onChangeHide("onlyWithContent")}
            control={<Checkbox color="default" size="small" />}
            label="Hide products with no content set"
          />
        </Box>
      </DialogContent>

      <Box
        display="flex"
        p={2}
        justifyContent="space-between"
        borderTop={`solid 1px ${theme.palette.borderColor}`}
      >
        <Fade in={totalFilters > 0}>
          <Button onClick={clearFilters} variant="underlined">
            Clear all filters
          </Button>
        </Fade>
        <Button onClick={onShowResults} variant="contained">
          Show results
        </Button>
      </Box>
    </Dialog>
  )
}

ProductSearchFilterDialog.propTypes = {
  open: bool,
  onClose: func.isRequired,
}

ProductSearchFilterDialog.defaultProps = {
  open: false,
}

export { ProductSearchFilterDialog }
