import {
  dialogClasses,
  dialogTitleClasses,
  iconButtonClasses,
  inputAdornmentClasses,
  tableRowClasses,
} from "@mui/material"
import { alpha, createTheme } from "@mui/material/styles"

const paletteTheme = createTheme({
  palette: {
    common: {
      black: "#111111",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fff",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    primary: {
      main: "rgba(82, 68, 136, 1)",
      dark: "rgba(69, 57, 114, 1)",
      light: "rgba(99, 87, 148, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      main: "#F59C00",
      contrastText: "#fff",
    },
    error: {
      main: "rgba(209, 45, 26, 1)",
      dark: "rgba(176, 38, 22, 1)",
      light: "rgba(216, 79, 63, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    warning: {
      main: "rgba(245, 156, 0, 1)",
      dark: "rgba(206, 131, 0, 1)",
      light: "rgba(255, 152, 0, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    info: {
      main: "rgba(2, 136, 209, 1)",
      dark: "rgba(1, 87, 155, 1)",
      light: "rgba(3, 169, 244, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    success: {
      main: "rgba(15, 177, 162, 1)",
      light: "rgba(53, 189, 177, 1)",
      dark: "rgba(13, 149, 136, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },

    // Custom colors
    borderColor: "#EBEBEB",
    ochre: {
      main: "#FFE750",
      contrastText: "#242105",
    },
  },
})

/**
 * @type {import('@mui/material').Components<import('@mui/material/styles').Theme>['MuiButton']}
 */
const buttonTheme = {
  defaultProps: {
    size: "small",
    variant: "outlined",
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      if (ownerState.variant === "underlined") {
        return {
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            textDecorationThickness: 1,
          },
        }
      }

      if (theme.palette[ownerState.color]) {
        return {
          "&:hover": {
            backgroundColor: theme.palette[ownerState.color].main,
            color: "#fff",
          },
        }
      }

      return undefined
    },
  },
  variants: [
    {
      props: { variant: "underlined" },
      style: {
        backgroundColor: "transparent",
        color: paletteTheme.palette.primary.main,
      },
    },
    {
      props: { variant: "text" },
      style: {
        "&:hover": {
          backgroundColor: alpha(paletteTheme.palette.primary.main, 0.08),
          color: paletteTheme.palette.primary.main,
        },
        "&:active": {
          backgroundColor: alpha(paletteTheme.palette.primary.main, 0.08),
          color: paletteTheme.palette.primary.main,
        },
      },
    },
  ],
}

export const latestTheme = createTheme({
  typography: {
    fontFamily: "Graphie",
    button: {
      textTransform: "none",
    },
    subtitle1: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      color: "rgba(0, 0, 0, 0.87)",
      letterSpacing: "0.15px",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      letterSpacing: "0.17px",
    },
  },
  components: {
    MuiLoadingButton: buttonTheme,
    MuiButton: buttonTheme,
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "1.188rem",
          lineHeight: "1.5rem",
          padding: "1rem",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.dx-full-dialog .${dialogClasses.paper}`]: {
            height: "100vh",
            width: "100vw",
          },
          [`.${dialogTitleClasses.root} .${iconButtonClasses.root} svg`]: {
            color: theme.palette.common.black,
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${inputAdornmentClasses.root} svg`]: {
            color: theme.palette.common.black,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderCollapse: "separate",
          boxSizing: "border-box",
          "&.bordered td:first-child": {
            borderLeft: `1px solid ${theme.palette.borderColor}`,
          },
          "&.bordered td:last-child, &.bordered th:last-child": {
            borderRight: `1px solid ${theme.palette.borderColor}`,
          },
          "&.bordered th": {
            borderTop: `1px solid ${theme.palette.borderColor}`,
          },
          td: {
            padding: "0.5rem",
          },
          th: {
            padding: "1rem 0.5rem",
            color: "rgba(0, 0, 0, 0.6)",
            weight: 400,
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.${tableRowClasses.hover}:hover td`]: {
            backgroundColor: "#f4f4f4",
          },
          ".editable-cell-icon": {
            backgroundColor: "#f4f4f4",
            zIndex: 1,
            width: "1.5rem",
            visibility: "hidden",
            position: "absolute",
            cursor: "pointer",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.borderColor,
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: "top",
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        },
      },
    },
  },
  palette: paletteTheme.palette,
})
