import React, { useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import ReactPlaceholder from "react-placeholder"
import { groupBy } from "lodash"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useFilterOptions, useSelectorSeletedOptions } from "./hooks"
import { Slideover } from "../ui"
import useShops from "../../hooks/use-shops"
import { mapBrandLayerName } from "../../util/brands"
import { EUR_SYMBOL } from "../../util/constants"

const filtersPlaceholder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 100, height: 32, margin: "0" }}
    type="rect"
  />
)

export const EMPTY_FILTERS = {}
const SORTED_OPTIONS_GROUPS = ["core", "category", "group"]
const DRAWER_WIDTH = "360px"

function LabelWrapper({ text, showTooltip }) {
  const { t } = useTranslation()
  const ref = useRef(null)

  if (!showTooltip) {
    return <Typography color="text.primary">{t(text)}</Typography>
  }

  return (
    <Tooltip
      arrow
      placement="left"
      title={
        ref.current?.offsetWidth < ref.current?.scrollWidth ? (
          <Typography variant="body2">{t(text)}</Typography>
        ) : (
          ""
        )
      }
    >
      <Typography
        ref={(el) => {
          ref.current = el
        }}
        color="text.primary"
        sx={{
          display: "block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: "nowrap",
        }}
      >
        {t(text)}
      </Typography>
    </Tooltip>
  )
}
LabelWrapper.propTypes = {
  text: PropTypes.string,
  showTooltip: PropTypes.bool,
}
LabelWrapper.defaultProps = {
  text: "",
  showTooltip: false,
}

function AssortmentAnalysisFilters() {
  const { t } = useTranslation()
  const { selectedFilters } = useSelectorSeletedOptions()
  const { filterOptions, setFilterOptions } = useFilterOptions()
  const { data: filterOptionsData, isIdle, isFetching, isSuccess } = filterOptions
  const [open, isOpen] = useState(false)
  const { getByCode } = useShops()
  const selected = useMemo(() => selectedFilters || EMPTY_FILTERS, [selectedFilters])

  const filtersApplied = useMemo(
    () => Object.values(selected).flat().length,
    [selected],
  )

  const handleOpen = () => {
    isOpen(true)
  }

  const handleClose = () => {
    isOpen(false)
  }

  const clearFilter = (filter) => {
    const filters = { ...selected }
    delete filters[filter]
    setFilterOptions({
      filterOptions: {
        ...filters,
      },
    })
  }

  const clearFilters = () => {
    setFilterOptions(EMPTY_FILTERS)
  }

  const setOptions = (filters) => {
    setFilterOptions({
      filterOptions: {
        ...selected,
        ...filters,
      },
    })
  }

  const handleArrayAttributeChange = (name, value, checked) => {
    let newArr = selected[name] || []
    if (checked) {
      newArr = [...new Set([...(selected[name] || []), value])]
    } else {
      newArr = newArr.filter((item) => item !== value)
    }
    setOptions({
      [name]: newArr,
    })
  }

  const handleBooleanAttributeChange = (name, value, checked) => {
    let newArr = selected[name] || []
    if (checked) {
      newArr = [...new Set([...newArr, value])]
    } else {
      newArr = newArr.filter((item) => item !== value)
    }
    setOptions({
      [name]: newArr,
    })
  }

  const setMinPrice = ({ target }) => {
    const [min, max] = selected.pricing?.[0]?.split(":") || [0, 0]
    setOptions({
      pricing: [`${target.value || min}:${max}`],
    })
  }
  const setMaxPrice = ({ target }) => {
    const [min, max] = selected.pricing?.[0]?.split(":") || [0, 0]
    setOptions({
      pricing: [`${min}:${target.value || max}`],
    })
  }

  const handleMinNumberAttributeChange = (name, value) => {
    const [min, max] = selected?.[name]?.[0]?.split(":") || [0, 0]
    setOptions({
      [name]: [`${value || min}:${max}`],
    })
  }
  const handleMaxNumberAttributeChange = (name, value) => {
    const [min, max] = selected?.[name]?.[0]?.split(":") || [0, 0]
    setOptions({
      [name]: [`${min}:${value || max}`],
    })
  }

  const mapGroupName = (group) => {
    switch (group) {
      case "core":
        return t("General attributes")
      case "category":
        return t("Category attributes")
      case "group":
        return t("Group attributes")
      default:
        return group
    }
  }

  const createOptionsElements = (attributeName, type, values) => {
    if (attributeName === "pricing") {
      return (
        <>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label={<LabelWrapper text="Minimum" />}
              value={selected?.pricing?.[0]?.split(":")?.[0] || ""}
              placeholder="0"
              onChange={setMinPrice}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
                "&.MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.48)",
                    borderWidth: "1px",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: EUR_SYMBOL,
                inputProps: { min: 0 },
              }}
            />
            <Divider variant="middle" orientation="horizontal" sx={{ width: 32 }} />
            <TextField
              size="small"
              label={<LabelWrapper text="Maximum" />}
              value={selected?.pricing?.[0]?.split(":")?.[1] || ""}
              placeholder={values[0].regularSuPrice || 9999}
              onChange={setMaxPrice}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
                "&.MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.48)",
                    borderWidth: "1px",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: EUR_SYMBOL,
                inputProps: { min: 0 },
              }}
            />
          </Box>
        </>
      )
    }

    const labelMapper = (value) =>
      (attributeName === "shop"
        ? getByCode(value).name
        : mapBrandLayerName(value) || "unknown"
      )
        ?.split("_")
        .join(" ")

    switch (type) {
      case "array":
        return (
          <FormGroup>
            {values
              .sort((a, b) => {
                const aVal = labelMapper(a) || ""
                const bVal = labelMapper(b) || ""
                return aVal.localeCompare(bVal, undefined, { numeric: true })
              })
              .map((val) => (
                <FormControlLabel
                  key={`${attributeName}_${val}`}
                  control={<Checkbox size="small" color="default" />}
                  onChange={({ target }) =>
                    handleArrayAttributeChange(
                      attributeName,
                      target.value,
                      target.checked,
                    )
                  }
                  sx={{
                    textTransform:
                      attributeName === "farmed_country" ? "uppercase" : "capitalize",
                    maxHeight: "24px",
                    width: "100%",
                    "& .MuiFormControlLabel-label": {
                      width: "95%",
                    },
                  }}
                  label={<LabelWrapper text={labelMapper(val)} showTooltip />}
                  value={val}
                  checked={selected[attributeName]?.indexOf(val) > -1}
                />
              ))}
          </FormGroup>
        )
      case "string":
        return (
          <FormGroup>
            {values
              .sort((a, b) => {
                const aVal = mapBrandLayerName(a) || "unknown"
                const bVal = mapBrandLayerName(b) || "unknown"
                return aVal.localeCompare(bVal, undefined, { numeric: true })
              })
              .map((val) => (
                <FormControlLabel
                  key={`${attributeName}_${val}`}
                  control={<Checkbox size="small" color="default" />}
                  onChange={({ target }) =>
                    handleArrayAttributeChange(
                      attributeName,
                      target.value,
                      target.checked,
                    )
                  }
                  sx={{
                    textTransform: "capitalize",
                    maxHeight: "24px",
                    width: "100%",
                    "& .MuiFormControlLabel-label": {
                      width: "95%",
                    },
                  }}
                  label={
                    <LabelWrapper
                      text={(mapBrandLayerName(val) || "unknown").split("_").join(" ")}
                      showTooltip
                    />
                  }
                  value={val}
                  checked={selected[attributeName]?.indexOf(val) > -1}
                />
              ))}
          </FormGroup>
        )
      case "boolean":
        return (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size="small" color="default" />}
              onChange={({ target }) =>
                handleBooleanAttributeChange(attributeName, "true", target.checked)
              }
              label={<LabelWrapper text="Yes" />}
              checked={selected[attributeName]?.indexOf("true") > -1}
              sx={{ maxHeight: "24px" }}
            />
            <FormControlLabel
              control={<Checkbox size="small" color="default" />}
              onChange={({ target }) =>
                handleBooleanAttributeChange(attributeName, "false", target.checked)
              }
              label={<LabelWrapper text="No" />}
              checked={selected[attributeName]?.indexOf("false") > -1}
              sx={{ maxHeight: "24px" }}
            />
          </FormGroup>
        )
      case "number":
      case "integer":
        return (
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label={<LabelWrapper text="Minimum" />}
              value={selected[attributeName]?.[0]?.split(":")?.[0] || ""}
              placeholder="0"
              onChange={({ target }) =>
                handleMinNumberAttributeChange(attributeName, target.value)
              }
              min="0"
              type="number"
              sx={{
                textAlign: "right",
                "&.MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.48)",
                    borderWidth: "1px",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            <Divider variant="middle" orientation="horizontal" sx={{ width: 32 }} />
            <TextField
              size="small"
              label={<LabelWrapper text="Maximum" />}
              value={selected[attributeName]?.[0]?.split(":")?.[1] || ""}
              placeholder="10"
              onChange={({ target }) =>
                handleMaxNumberAttributeChange(attributeName, target.value)
              }
              min="0"
              type="number"
              sx={{
                textAlign: "right",
                "&.MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.48)",
                    borderWidth: "1px",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Box>
        )

      default:
        return <LabelWrapper text="Not Available" />
    }
  }

  const clearAllAction = () =>
    filtersApplied > 0 && (
      <Button
        variant="underlined"
        size="small"
        data-testid="clear-button"
        onClick={clearFilters}
        sx={{ p: "0 16px" }}
      >
        {t("Clear all")}
      </Button>
    )

  const options = useMemo(
    () => groupBy(filterOptionsData || [], ({ attributeType }) => attributeType),
    [filterOptionsData],
  )

  if (isIdle || isFetching) {
    return filtersPlaceholder
  }

  if (isSuccess && filterOptionsData) {
    return (
      <>
        <Box display="flex" gap={3} padding={0}>
          <Button
            size="small"
            onClick={handleOpen}
            variant="text"
            startIcon={<Icon class="material-symbols-outlined">page_info</Icon>}
            data-testid="assortment-filters-button"
          >
            <Typography
              color="primary"
              fontSize={12}
              fontWeight={600}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {t("Filters")}
            </Typography>
            <Badge
              badgeContent={filtersApplied}
              color="primary"
              sx={{ pl: 2, position: "relative", transform: "none" }}
            />
          </Button>
        </Box>
        <Slideover
          title={<LabelWrapper text="Filters" />}
          open={open}
          onClose={handleClose}
          actions={clearAllAction()}
          data-testid="assortment-filters-slideover"
          PaperProps={{
            sx: { minWidth: DRAWER_WIDTH },
          }}
        >
          {SORTED_OPTIONS_GROUPS.map((group) => {
            const option = options[group]
            if (!option) return null
            return (
              <Box key={`${group}`}>
                <Container sx={{ px: "16px !important", py: 2 }}>
                  <Typography color="text.secondary" variant="body2">
                    {mapGroupName(group)}
                  </Typography>
                </Container>
                {option.map((filter, i) => (
                  <Accordion
                    disableGutters
                    elevation={0}
                    key={`${filter.attributeType}-${filter.attributeName}`}
                    data-testid={`assortment-filter-accordion-${filter.attributeType}-${filter.attributeName}`}
                    sx={{ "&.Mui-expanded::before": { opacity: 1 } }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <IconButton size="small">
                          <Icon class="material-symbols-outlined">
                            keyboard_arrow_down
                          </Icon>
                        </IconButton>
                      }
                      id={`panel${i}-header-${filter.attributeName}`}
                      aria-controls={`panel${i}-content-${filter.attributeName}`}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="body1">
                          {t(filter.attributeName?.split("_").join(" "))}
                          {selected[filter.attributeName]?.length > 0 &&
                            ` (${selected[filter.attributeName]?.length})`}
                        </Typography>

                        {selected[filter.attributeName]?.length > 0 && (
                          <Button
                            size="small"
                            variant="underlined"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation()
                              clearFilter(filter.attributeName)
                            }}
                            sx={{ p: "0 16px" }}
                          >
                            {t("Clear")}
                          </Button>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ maxWidth: DRAWER_WIDTH }}>
                      {createOptionsElements(
                        filter.attributeName,
                        filter.type,
                        filter.values,
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )
          })}
        </Slideover>
      </>
    )
  }

  return (
    <Box display="flex" gap={3} padding={0}>
      <Button
        disabled
        size="small"
        variant="text"
        startIcon={<Icon class="material-symbols-outlined">page_info</Icon>}
        data-testid="assortment-filters-button"
      >
        <Typography
          color="primary"
          fontSize={12}
          fontWeight={600}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {t("Filters")}
        </Typography>
      </Button>
    </Box>
  )
}

AssortmentAnalysisFilters.propTypes = {}
AssortmentAnalysisFilters.defaultProps = {}

export { AssortmentAnalysisFilters }
