import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { replace } from "connected-react-router"
import AssortmentToolPresenter from "./AssortmentToolPresenter"

const assortmentToolRoutes = Object.freeze({
  base: "/assortment",
  analysis: "/assortment/analysis/tool",
})

const assortmentFeatureFlags = []

function isBaseRoute(pathname) {
  return [assortmentToolRoutes.base, `${assortmentToolRoutes.base}/`].includes(pathname)
}

function AssortmentTool({ location, ...rest }) {
  const dispatch = useDispatch()
  const { pathname } = location

  useEffect(() => {
    if (isBaseRoute(pathname)) {
      dispatch(replace(assortmentToolRoutes.analysis))
    }
  }, [dispatch, pathname])

  return <AssortmentToolPresenter routes={assortmentToolRoutes} {...rest} />
}

AssortmentTool.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AssortmentTool
export { assortmentToolRoutes, assortmentFeatureFlags }
