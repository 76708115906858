import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import "./cookie-blocker.scss"

function waitForElm(selector) {
  return new Promise((resolve, _reject) => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector))
      return
    }

    const observer = new MutationObserver((_mutation) => {
      if (document.querySelector(selector)) {
        observer.disconnect()
        resolve(document.querySelector(selector))
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

function CookieBlocker({ active }) {
  const widgetId = "adopt-controller-button"
  const bannerId = "cookie-banner"

  useEffect(() => {
    if (!active) return null

    waitForElm(`#${widgetId}`).then((elm) => {
      const element = elm
      element.classList.add("hide-btn")
    })

    waitForElm(`#${bannerId}`).then((elm) => {
      const element = elm
      element.classList.add("resize-banner")
    })

    return null
  }, [active])

  return <Box id={`${active ? "no" : "allow"}-cookie-banner-widget`} />
}

CookieBlocker.propTypes = {
  active: PropTypes.bool,
}

CookieBlocker.defaultProps = {
  active: false,
}

export { CookieBlocker, waitForElm }
