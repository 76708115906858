import React from "react"
import mixpanel from "mixpanel-browser"
import PropTypes from "prop-types"
import ReactPlaceholder from "react-placeholder"
import { Box, Icon } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { useTranslation } from "react-i18next"
import {
  useProductGroupExport,
  useProductGroupsOutline,
  useProductGroupFilter,
} from "./hooks"
import { DXSnackbar } from "../ui"

const ButtonPalceHolder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 128, height: 32, margin: "0" }}
    type="rect"
  />
)

function AssortmentAnalysisExportCategoryButton({
  category,
  label,
  variant,
  ...props
}) {
  const { t } = useTranslation()
  const { categoryExport, exportCategory } = useProductGroupExport()
  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()
  const [productGroupId, _, all] = useProductGroupFilter()
  const isAll = all === "true"

  if (isIdle || isFetching) {
    return ButtonPalceHolder
  }

  if (isError) {
    return null
  }

  if (!productGroups || (productGroups && productGroups.length === 0)) {
    return (
      <LoadingButton
        {...props}
        size="small"
        color="primary"
        variant={variant}
        startIcon={<Icon className="material-symbols-outlined">ios_share</Icon>}
        disabled
      >
        {label || t("Export Excel")}
      </LoadingButton>
    )
  }

  const productGroup =
    productGroups.find((opt) => opt.id === productGroupId) || productGroups[0]

  let { id: groupId } = productGroup

  if (category || isAll) {
    groupId = undefined
  }

  async function handleExportClick(e) {
    e.preventDefault()
    const cat = isAll ? productGroupId : category
    exportCategory(cat, groupId)
    mixpanel.track("A3Tool - Export Category", { category: cat, groupId })
    e.stopPropagation()
  }

  return (
    <>
      <LoadingButton
        {...props}
        size="small"
        onClick={handleExportClick}
        color="primary"
        variant={variant}
        loading={categoryExport.loading}
        loadingPosition="start"
        startIcon={<Icon className="material-symbols-outlined">ios_share</Icon>}
        data-testid="assortment-export-category"
      >
        {label || t("Export Excel")}
      </LoadingButton>
      <DXSnackbar autoHideDuration={3000} open={categoryExport.loading}>
        <Box alignItems="center" display="flex" justifyContent="start" width="100%">
          <Icon
            className="material-symbols-outlined"
            sx={{
              mr: 1,
            }}
          >
            ios_share
          </Icon>
          {t("Exporting Excel")}
        </Box>
      </DXSnackbar>
    </>
  )
}

AssortmentAnalysisExportCategoryButton.propTypes = {
  category: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
}

AssortmentAnalysisExportCategoryButton.defaultProps = {
  category: null,
  label: null,
  variant: "outlined",
}

export { AssortmentAnalysisExportCategoryButton }
