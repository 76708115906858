import * as Sentry from "@sentry/react"
import axios from "axios"
import DataLoader from "dataloader"
import queryString from "query-string"

import { REPORTS_PAGE_SIZE, SEARCH_PAGE_SIZE } from "../util/config"
import { Logger } from "../util/log"

import {
  DIMENSION_PRICE,
  STEP_PRICE,
  MAX_PRICE,
  DIMENSION_UOM_PRICE,
} from "../ducks/pages/assortment-analysis/constants"
import { getClient } from "../hooks/use-authentication"
import { arrayWithElementsOrUndefined } from "../util/array"
import { logError } from "../util/error"
import { getSetting, getValue, KEY_CUSTOMER, KEY_NO_CACHE } from "../util/local-storage"
import { getMatchSourceFilterOptions } from "../util/match-source"
import { STATUS_UNMATCHED } from "../util/match-status"
import {
  MULTIFACTOR_EQUALS,
  MULTIFACTOR_LESS_THAN,
  MULTIFACTOR_MORE_THAN,
  MULTIFACTOR_UNDEFINED,
} from "../util/product-factor"
import { DEFAULT_PATH_DELIMITER, pathsToTree } from "../util/tree"
import { QP_MODE } from "../util/query-param"

const log = new Logger("api")

const HEADER_DX_CUSTOMER = "x-dx-customer"
const NO_CACHE_HEADER = "x-dx-disable-cache"
const one = (value) => value || undefined
const many = arrayWithElementsOrUndefined

export async function createFilter(filter) {
  const result = await axios.post("/api/filter", filter)

  return result.data
}

const daltixProductLoader = new DataLoader(
  async (ids) => {
    log.debug("[data-loader] loading daltix products:", ids)

    const result = await axios.get("/api/daltix-products/filtered", {
      params: {
        ids: ids.join(","),
      },
    })

    return ids.map((id) => result.data[id])
  },
  {
    maxBatchSize: 50,
  },
)

const dashboardLoader = new DataLoader(async (ids) => {
  log.debug("[data-loader] loading dashboards:", ids)
  const filterId = await createFilter({ ids })

  const result = await axios.get(`/api/dashboards/filtered/${filterId}`)

  return ids.map((id) => result.data[id])
})

const referenceProductLoader = new DataLoader(async (ids) => {
  log.debug("[data-loader] loading reference products:", ids)

  const filterId = await createFilter({ ids })

  const result = await axios.get("/api/ref-products/filtered", {
    params: {
      filterId,
    },
  })

  return ids.map((id) => ({ id, ...result.data[id] }))
})

const matchLoader = new DataLoader(
  async (ids) => {
    log.debug("[data-loader] loading matches:", ids)

    const filterId = await createFilter({ ids })

    const result = await axios.get("/api/matches/filtered", {
      params: {
        filterId,
      },
    })

    return ids.map((id) => ({ id, ...result.data[id] }))
  },
  {
    batchScheduleFn: (callback) => setTimeout(callback, 1000),
  },
)

if (["production", "test"].includes(process.env.NODE_ENV)) {
  log.info("Axios is now using REACT_APP_API_URL, instead of CRA Proxy")

  axios.defaults.baseURL = process.env.REACT_APP_API_URL
}

function getAccessTokenHeader(token) {
  if (!token) {
    throw new Error("No token found")
  }

  return { Authorization: `Bearer ${token}` }
}

export async function getProfile() {
  const result = await axios.get("/api/profile")

  return result.data
}

export async function logOut() {
  return true
}

export async function listReports({ page = 1, pageSize = REPORTS_PAGE_SIZE, query }) {
  const result = await axios.get("/api/reports", {
    params: {
      page,
      pageSize,
      query,
    },
  })
  return result.data
}

export async function detailDashboard(id) {
  return dashboardLoader.load(id)
}

export async function getReportDownloadUrl(url) {
  const filterId = await createFilter({ url })
  const result = await axios.get(`/api/reports/download/${filterId}`)

  return result.data.downloadUrl
}

export async function listReferenceProducts({
  page = 1,
  pageSize = 5,
  ids,
  query,
  countries,
  families,
  status,
  sort,
  categories,
  l2Categories,
  l3Categories,
  l4Categories,
  source,
  strategy,
}) {
  log.debug(
    "Listing reference products. Status:",
    status,
    "Countries:",
    countries,
    "Categories:",
    categories,
    "Level2 Categories:",
    l2Categories,
    "Level3 Categories:",
    l3Categories,
    "Level4 Categories:",
    l4Categories,
    "Source:",
    source,
    "Strategy:",
    strategy,
  )

  const filterId = await createFilter({
    page,
    pageSize,
    countries,
    families,
    statuses: status,
    categories,
    l2Categories,
    l3Categories,
    l4Categories,
    ids,
    q: query,
    sort,
    source,
    mode: strategy,
  })

  const result = await axios.get("/api/ref-products", {
    params: {
      filterId,
    },
  })

  return result.data
}

export async function getRefProdAutoCompleteSuggestions({
  query,
  source,
  strategy,
  limit,
  status,
  countries,
  families,
  categories,
  l2Categories,
  l3Categories,
  l4Categories,
}) {
  log.debug("Listing reference products. Status:", status, "Categories:", categories)

  const filterId = await createFilter({
    q: query,
    source,
    mode: strategy,
    limit,
    countries,
    families,
    statuses: status,
    categories,
    l2Categories,
    l3Categories,
    l4Categories,
  })

  const result = await axios.get("/api/ref-products/auto-complete", {
    params: {
      filterId,
    },
  })

  return result.data
}

/**
 * Batch loads many reference products by ID.
 *
 * @param {Array<{ id: string, invalidateCache?: boolean }>} args - An array of objects representing the reference products to be retrieved.
 * @param {string} args[].id - Reference product ID used to perform the batched fetch request.
 * @param {boolean} args[].invalidateCache - When true, the cache is cleared before retrieving the reference product.
 *
 * @returns {Promise<Array<unknown>>} - A Promise that resolves to an array of loaded reference products.
 */
export async function getReferenceProducts(args) {
  const ids = args.reduce((acc, curr) => {
    if (curr.invalidateCache) {
      referenceProductLoader.clear(curr.id)
    }

    acc.push(curr.id)

    return acc
  }, [])

  return referenceProductLoader.loadMany(ids)
}

export async function fetchCustomerAssortmentAnalysis(filters) {
  log.debug(`Retrieving customer assortment for analysis purposes`)
  const selectedFilters = filters.filterOptions

  /**
   * TODO @aatool this should be an option in the UI.
   *
   * When DIMENSION_PRICE render a range selector (min, max)
   * When DIMENSION_PRICE render a step definition input field
   */
  const priceFilters =
    filters.dimension === DIMENSION_PRICE || filters.dimension === DIMENSION_UOM_PRICE
      ? {
          priceRangeBoundaries: [0, MAX_PRICE],
          priceRangeStep: filters?.step || STEP_PRICE,
        }
      : {}

  let filterOptions = []

  if (selectedFilters) {
    filterOptions = Object.entries(selectedFilters).map(([key, value]) => ({
      [key]: many(value ? value.map((v) => `${v}`) : value),
    }))
  }

  const result = await axios.get("/api/assortment/analysis", {
    params: {
      productGroupId: one(filters.productGroupId) || 0,
      dimension: one(filters.dimension),
      sort: many(filters.sort),
      all: one(filters.all),
      threshold: one(filters.threshold),
      ...priceFilters,
      ...Object.assign({}, ...filterOptions),
    },
    paramsSerializer: (params) => {
      const paramsCopy = { ...params }
      delete paramsCopy[QP_MODE]
      return queryString.stringify(paramsCopy, { arrayFormat: "bracket" })
    },
  })

  return result.data
}

export async function fetchCustomerAssortmentFilterOptions(
  productGroupId,
  categoryId,
  all,
) {
  const result = await axios.get("/api/assortment/filters", {
    params: {
      categoryId: one(categoryId) || 0,
      productGroupId: one(productGroupId) || 0,
      all: one(all),
    },
  })

  return result.data
}

export async function fetchCustomerAssortmentOutline() {
  const result = await axios.get("/api/assortment/outline")

  return result.data
}

export async function fetchCustomerAssortmentAnalysisExport(
  categoryId,
  productGroupId,
  filters = {},
) {
  const options = filters.filterOptions

  let filterOptions = []
  if (options) {
    filterOptions = Object.entries(options).map(([key, value]) => ({
      [key]: many(value ? value.map((v) => `${v}`) : value),
    }))
  }

  const selectedFilters = {
    threshold: one(filters.threshold),
    ...Object.assign({}, ...filterOptions),
  }
  delete selectedFilters[QP_MODE]

  const result = await axios.get("/api/assortment/export", {
    responseType: "blob",
    params: {
      categoryId: one(categoryId) || 0,
      productGroupId: one(productGroupId) || 0,
      filters: encodeURIComponent(btoa(JSON.stringify(selectedFilters))),
    },
  })
  // create file link in browser's memory
  const href = URL.createObjectURL(result.data)

  // create "a" HTML element with href to file & click
  const link = document.createElement("a")
  link.href = href
  link.setAttribute(
    "download",
    result.headers["content-disposition"]?.split("filename=")[1] ||
      `assortment-analysis_${Date.now()}.xlsx`,
  )
  link.click()

  // remove ObjectURL
  URL.revokeObjectURL(href)

  return true
}

export async function fetchReferenceProductsMatchesByShop({
  page,
  pageSize,
  requestTotals,
  ...filters
}) {
  let filterId

  if (filters) {
    const {
      matchCount,
      matchCountries,
      matchShops,
      matchStatus,
      referenceProductFamilies,
      referenceProductCategories,
      referenceProductSearchFacet,
      referenceProductSearchTerm,
      referenceProductSearchStrategy,
    } = filters

    const categoriesFilterTree = pathsToTree(
      referenceProductCategories,
      DEFAULT_PATH_DELIMITER,
      "subcategories",
    )

    const filterBody = {
      exactlyZeroMatches: matchCount === 0 ? true : undefined,
      matchCountries: many(matchCountries),
      matchShops: many(matchShops),
      matchStatus: many(matchStatus),
      referenceProductCategories: many(categoriesFilterTree),
      referenceProductFamilies: many(referenceProductFamilies),
      referenceProductSearchFacet: one(referenceProductSearchFacet),
      referenceProductSearchTerm: one(referenceProductSearchTerm),
      referenceProductSearchStrategy: one(referenceProductSearchStrategy),
    }

    if (Object.values(filterBody).some((value) => value !== undefined)) {
      log.debug("Creating prefiltered resource for reference products matches by shop")

      filterId = await createFilter(filterBody)
    }
  }

  log.debug(`Retrieving reference products matches by shop. Filter ID ? '${filterId}'`)

  const result = await axios.get("/api/ref-products/matches/shops", {
    params: {
      page,
      pageSize,
      withTotals: requestTotals || true,
      filterId,
    },
  })

  return result.data
}

/**
 * Batch loads many Daltix products by ID.
 *
 * @param {Array<{ id: string, invalidateCache?: boolean }>} args - An array of objects representing Daltix products to be retrieved.
 * @param {string} args[].id - Daltix product ID used to perform the batched fetch request.
 * @param {boolean} args[].invalidateCache - When true, the cache is cleared before retrieving the Daltix product.
 *
 * @returns {Promise<Array<unknown>>} - A Promise that resolves to an array of loaded Daltix products.
 */
export async function getDaltixProducts(args) {
  const ids = args.reduce((acc, curr) => {
    if (curr.invalidateCache) {
      daltixProductLoader.clear(curr.id)
    }

    acc.push(curr.id)

    return acc
  }, [])

  return daltixProductLoader.loadMany(ids)
}

export async function getSearchFilters(query) {
  const result = await axios.get("/api/daltix-products/searchFilters", {
    params: {
      q: query,
    },
  })

  return result.data
}

export async function searchDaltixProducts(
  query,
  {
    countries,
    shops,
    brands,
    availabilities,
    page,
    pageSize = SEARCH_PAGE_SIZE,
    sort,
    direction,
    fields = ["_id"],
    ids,
    notIds,
    minPrice,
    maxPrice,
    referenceProductId,
    content,
    minFactor,
    maxFactor,
    onlyWithPrice = false,
    onlyWithContent = false,
    onlyWithMF = false,
  } = {},
) {
  const result = await axios.get("/api/daltix-products/search", {
    params: {
      q: query,
      referenceProductId,
      shops,
      countries,
      brands,
      availabilities,
      page,
      pageSize,
      sort,
      direction,
      fields,
      ids,
      notIds,
      minPrice,
      maxPrice,
      content,
      minFactor,
      maxFactor,
      onlyWithPrice,
      onlyWithContent,
      onlyWithMF,
    },
  })

  return result.data
}

export async function listExistingMatches(
  id,
  { withDiscarded, sort, direction, ...filters } = {},
) {
  // remove unmatched status from filters as the backend does not use it
  const status = filters.status?.filter((s) => s !== STATUS_UNMATCHED)
  const result = await axios.get(`/api/ref-products/${id}/existing-matches`, {
    params: {
      ...filters,
      status,
      withDiscarded,
      sort,
      direction,
    },
  })

  return result.data
}

/**
 * Batch loads many matches by ID.
 *
 * @param {Array<{ id: string, invalidateCache?: boolean }>} args - An array of objects representing matches to be retrieved.
 * @param {string} args[].id - Match ID used to perform the batched fetch request.
 * @param {boolean} args[].invalidateCache - When true, the cache is cleared before retrieving the match.
 *
 * @returns {Promise<Array<unknown>>} - A Promise that resolves to an array of loaded matches.
 */
export async function getMatches(args) {
  const ids = args.reduce((acc, curr) => {
    if (curr.invalidateCache) {
      matchLoader.clear(curr.id)
    }

    acc.push(curr.id)

    return acc
  }, [])

  return matchLoader.loadMany(ids)
}

export async function getReferenceProductsSummary(options) {
  let referenceProductId
  if (options) {
    referenceProductId = options.referenceProductId
  }
  const result = await axios.get("/api/ref-products/summary", {
    params: {
      referenceProductId,
    },
  })
  return result.data
}

export async function listReferenceProductCategories() {
  const result = await axios.get("/api/ref-products/categories")

  return result.data
}

// matches

export async function listMatchedReferenceProductsCategories() {
  const result = await axios.get("/api/matches/ref-prod-categories")
  return result.data
}

export async function getMatchSources() {
  const result = await axios.get("/api/matches/sources")
  return result.data
}

export async function getMatchStatusSummary({ referenceProductId } = {}) {
  const result = await axios.get("/api/matches/status-summary", {
    params: {
      referenceProductId,
    },
  })
  return result.data
}

export async function listCountriesWithMatches() {
  const result = await axios.get("/api/matches/countries")
  return result.data
}

export async function listMatches({
  refProdCountries,
  refProdFamilies,
  refProdQuery,
  categories,
  l2Categories,
  l3Categories,
  l4Categories,
  sources,
  statuses,
  countries,
  shops,
  availabilities,
  factors,
  matchType,
  page = 1,
  pageSize = 5,
  source,
  strategy,
}) {
  const filterId = await createFilter({
    refProdQuery,
    refProdCountries,
    refProdFamilies,
    categories,
    l2Categories,
    l3Categories,
    l4Categories,
    sources,
    statuses,
    countries,
    shops,
    availabilities,
    factors,
    matchType,
    page,
    pageSize,
    source,
    mode: strategy,
  })

  const result = await axios.get("/api/matches/", {
    params: {
      filterId,
    },
  })
  return result.data
}

export async function getShopsFromMatches({
  refProdCountries,
  refProdFamilies,
  refProdQuery,
  categories,
  l2Categories,
  l3Categories,
  l4Categories,
  sources,
  statuses,
  countries,
  availabilities,
  factors,
  matchType,
  source,
  strategy,
}) {
  const filterId = await createFilter({
    refProdQuery,
    refProdCountries,
    refProdFamilies,
    categories,
    l2Categories,
    l3Categories,
    l4Categories,
    sources,
    statuses,
    countries,
    availabilities,
    factors,
    matchType,
    source,
    mode: strategy,
  })
  const result = await axios.get("/api/matches/shops", {
    params: {
      filterId,
    },
  })
  return result.data
}

export async function upsertManualMatch({
  referenceProductId,
  daltixProductId,
  shop,
  country,
  status,
  factor,
}) {
  const result = await axios.post("/api/matches/upsert", {
    referenceProductId,
    daltixProductId,
    shop,
    country,
    status,
    factor,
  })
  return result.data
}

export async function updateMatchBasicInfo({
  referenceProductId,
  daltixProductId,
  factor,
  labels,
  comment,
}) {
  const result = await axios.post("/api/matches/update-basic", {
    referenceProductId,
    daltixProductId,
    factor,
    labels,
    comment,
  })
  return result.data
}

export async function approveMatch({ referenceProductId, daltixProductId }) {
  const result = await axios.post("/api/matches/approve", {
    referenceProductId,
    daltixProductId,
  })
  return result.data
}

export async function removeMatchApproval({ referenceProductId, daltixProductId }) {
  const result = await axios.post("/api/matches/remove-approval", {
    referenceProductId,
    daltixProductId,
  })
  return result.data
}

export async function discardMatch({ referenceProductId, daltixProductId }) {
  const result = await axios.post("/api/matches/discard", {
    referenceProductId,
    daltixProductId,
  })
  return result.data
}

export async function restoreMatch({ referenceProductId, daltixProductId }) {
  const result = await axios.post("/api/matches/restore", {
    referenceProductId,
    daltixProductId,
  })
  return result.data
}

export async function reportError(error) {
  let forwardError = error
  let forwardErrorInfo = {}

  if (!(error instanceof Error) && error.message) {
    forwardError = new Error(error.message)
    forwardErrorInfo = error
  } else if (error.config && error.config.url) {
    // API errors
    forwardErrorInfo.url = error.config.url
  }

  logError(forwardError, forwardErrorInfo)
}

export async function listProductsWithMatchesByIds({
  referenceProductId,
  ids,
  withDiscarded = true,
  ...filters
}) {
  const { data: dxProducts } = await axios.get("/api/daltix-products/filtered", {
    params: {
      ids: ids.join(","),
    },
  })

  const { matches } = await listExistingMatches(referenceProductId, {
    withDiscarded,
    ...filters,
  })

  ids.forEach((id) => {
    const matched = matches.find((m) => m.daltix_id === id)
    dxProducts[id] = {
      data: { ...dxProducts[id], matched },
    }
  })

  return dxProducts
}

export async function listProductsWithMatches(referenceProductId, { query, filters }) {
  const fields = [
    "images",
    "name",
    "is_active",
    "source",
    "shop",
    "brand",
    "eans",
    "contents_v1",
    "price_summary",
    "country",
    "display_url",
  ]

  async function listOnlyUnmatched() {
    const { matches } = await listExistingMatches(referenceProductId, {
      withDiscarded: true,
      ...filters,
    })
    const matchesIds = matches.map((m) => m.daltix_id)

    const dxProducts = await searchDaltixProducts(query, {
      ...filters,
      fields,
      notIds: await createFilter({ notIds: matchesIds }),
      referenceProductId,
    })

    return {
      rows: dxProducts.products,
      total: dxProducts.meta.total,
    }
  }

  function isOnlyMatches() {
    // empty query and unmatched will list only matches
    if (query === "" && filters.status.every((s) => s !== STATUS_UNMATCHED)) {
      return true
    }

    if (filters.status.some((s) => s === STATUS_UNMATCHED)) {
      return false
    }

    if (filters.status.length > 0) {
      return true
    }

    if (filters.source.length > 0) {
      return true
    }

    if (filters.factors.length === 0) {
      return false
    }

    const hasUnmatchedFactor = filters.factors.some(
      (f) => f === MULTIFACTOR_EQUALS || f === MULTIFACTOR_UNDEFINED,
    )

    return !hasUnmatchedFactor
  }

  async function listOnlyMatches() {
    const { matches } = await listExistingMatches(referenceProductId, {
      withDiscarded: true,
      ...filters,
    })

    const filteredMatches = matches.filter(filterMatch)
    const matchesIds = filteredMatches.map((m) => m.daltix_id)

    if (matchesIds.length === 0) {
      return {
        rows: [],
        total: 0,
      }
    }

    const { data: dxProducts } = await axios.get("/api/daltix-products/filtered", {
      params: {
        ids: matchesIds.join(","),
      },
    })

    const rows = Object.values(dxProducts)
      .map((p) => ({
        ...p,
        matched: filteredMatches.find((m) => m.daltix_id === p.id),
      }))
      .filter((p) => {
        // filter if we don't want products without a price
        if (filters.onlyWithPrice) {
          return p.price !== undefined
        }
        // filter by price since we can't filter via listExistingMatches due to the price being only in opensearch
        if (filters.maxPrice) {
          return p.price >= (filters.minPrice || 0) && p.price <= filters.maxPrice
        }
        return true
      })

    if (filters.sort === "price" || filters.sort === "adjustedPrice") {
      rows.sort((a, b) => {
        let factorA = 1
        let factorB = 1
        if (filters.sort === "adjustedPrice") {
          factorA = a.matched?.mult_factor || 1
          factorB = b.matched?.mult_factor || 1
        }
        const priceA = (a.price || 0) * factorA
        const priceB = (b.price || 0) * factorB
        return filters.direction === "asc" ? priceA - priceB : priceB - priceA
      })
    }

    return {
      rows,
      total: rows.length,
    }
  }

  function filterMatch(match) {
    const hasStatus =
      filters.status.length === 0 || filters.status.includes(match.status)
    if (!hasStatus) {
      return false
    }

    const [sourceAdapted] = getMatchSourceFilterOptions([match.match_source]).map(
      (s) => s.value,
    )

    const hasSource =
      filters.source.length === 0 || filters.source.includes(sourceAdapted)
    if (!hasSource) {
      return false
    }

    if (filters.factors.length === 0) {
      return true
    }

    return filters.factors.some((factor) => {
      if (factor === MULTIFACTOR_EQUALS) {
        return match.mult_factor === 1
      }

      if (factor === MULTIFACTOR_LESS_THAN) {
        return match.mult_factor < 1
      }

      if (factor === MULTIFACTOR_MORE_THAN) {
        return match.mult_factor > 1
      }

      return match.mult_factor === undefined || match.mult_factor === null
    })
  }

  async function listBoth() {
    const { matches } = await listExistingMatches(referenceProductId, {
      withDiscarded: true,
      ...filters,
    })
    const matchesToBeRemoved = matches
      .filter((m) => !filterMatch(m))
      .map((m) => m.daltix_id)

    const dxProducts = await searchDaltixProducts(query, {
      ...filters,
      fields,
      notIds: matchesToBeRemoved,
      referenceProductId,
    })

    const rows = dxProducts.products.map((p) => ({
      ...p,
      matched: matches.find((m) => m.daltix_id === p.id),
    }))

    return {
      rows,
      total: dxProducts.meta.total,
    }
  }

  const isOnlyUnmatched =
    filters.status.length === 1 && filters.status[0] === STATUS_UNMATCHED
  const isUnmatched = filters.status?.some((s) => s === STATUS_UNMATCHED)

  if (!query && isUnmatched) {
    return {
      rows: [],
      total: 0,
    }
  }

  if (isOnlyUnmatched) {
    return listOnlyUnmatched()
  }

  if (isOnlyMatches()) {
    return listOnlyMatches()
  }

  return listBoth()
}

export async function createFlag({ referenceProductId, shop, country }) {
  await axios.post("/api/matches/flag", {
    referenceProductId,
    shop,
    country,
  })
}

export async function deleteFlag({ referenceProductId, shop, country }) {
  await axios.delete("/api/matches/flag", {
    data: { referenceProductId, shop, country },
  })
}

export async function getEmbedURL(appName) {
  const result = await axios.post("/api/embed-url", {
    appName,
  })

  return result.data
}

const publicPaths = []
axios.interceptors.request.use(
  async (originalRequest) => {
    log.debug("originalRequest:", originalRequest)

    if (
      !originalRequest.url.startsWith("http") &&
      !publicPaths.includes(originalRequest.url)
    ) {
      const token = await getClient().getTokenSilently()

      if (token) {
        log.debug("adding token to request")

        const headers = {
          ...originalRequest.headers,
          ...getAccessTokenHeader(token),
        }

        const customerCode = getSetting(KEY_CUSTOMER)
        if (customerCode) {
          headers[HEADER_DX_CUSTOMER] = customerCode
        }

        const noCache = getValue(KEY_NO_CACHE)
        if (noCache) {
          headers[NO_CACHE_HEADER] = "no-cache"
        }

        return {
          ...originalRequest,
          headers,
        }
      }
    }

    return originalRequest
  },
  (err) => Promise.reject(err),
)
