/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import PropTypes from "prop-types"
import React, { useState } from "react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { Badge, Box, Icon, Link, Tooltip, Typography } from "@mui/material"
import styles from "./ArticleDetailEntry.module.scss"

export function ArticleDetailEntry({
  title,
  value,
  slot,
  debug,
  unsure,
  unvalidated,
  endNode,
  link,
  tooltip,
}) {
  const [hover, setHover] = useState(false)
  const { t } = useTranslation()
  const handleLink = (text, url) => {
    if (!url) {
      return text
    }
    return (
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        sx={{ alignItems: "center", display: "flex" }}
        underline="hover"
      >
        <Icon className="material-symbols-outlined">link</Icon> {text}
      </Link>
    )
  }

  return (
    <div
      className={styles.container}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Tooltip
          title={
            unvalidated
              ? t("Unvalidated attribute")
              : unsure
                ? t("Unsure attribute")
                : ""
          }
          placement="right"
        >
          <Badge
            color={unvalidated ? "error" : unsure ? "ochre" : "none"}
            variant="dot"
            invisible={!unsure && !unvalidated}
            sx={{ paddingRight: 1, display: "flex", alignItems: "center" }}
          >
            <Typography component="h1" className={styles.title}>
              {title}
            </Typography>
            {tooltip && (
              <>
                &nbsp;&nbsp;
                <Tooltip title={tooltip}>
                  <Icon
                    color="text.secondary"
                    fontSize="small"
                    className="material-symbols-outlined"
                    sx={{ visibility: hover ? "visible" : "hidden" }}
                  >
                    info
                  </Icon>
                </Tooltip>
              </>
            )}
          </Badge>
        </Tooltip>
        {endNode}
      </Box>
      <div className={styles.content}>
        {slot}
        <Typography className={clsx(styles.value, debug && styles.debug)}>
          {handleLink(value, link)}
        </Typography>
      </div>
    </div>
  )
}

ArticleDetailEntry.propTypes = {
  title: PropTypes.string.isRequired,
  slot: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  debug: PropTypes.bool,
  unsure: PropTypes.bool,
  unvalidated: PropTypes.bool,
  endNode: PropTypes.node,
  link: PropTypes.string,
  tooltip: PropTypes.string,
}

ArticleDetailEntry.defaultProps = {
  slot: null,
  value: "",
  debug: false,
  unsure: false,
  unvalidated: false,
  endNode: null,
  link: "",
  tooltip: "",
}
