import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { titleCase } from "text-case"
import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import { Tooltip, Typography } from "@mui/material"
import styles from "./AssortmentAnalysisArticleDataCell.module.scss"
import { ArticlePropType } from "../../prop-types"
import { Table } from "../../../ui"
import { EUR_SYMBOL, INFINITY_SYMBOL } from "../../../../util/constants"
import { ArticleDetail } from "./ArticleDetail"
import { useSetHighlight } from "../../hooks/useSetHighlight"
import {
  DIMENSION_PRICE,
  DIMENSION_UOM_PRICE,
} from "../../../../ducks/pages/assortment-analysis/constants"

function Article({ brand, content, imageSources, name, displayPrice, priceUnit }) {
  const { t } = useTranslation()
  const maxWidth = 185

  const priceText =
    displayPrice === INFINITY_SYMBOL
      ? t("Not Available")
      : `${EUR_SYMBOL}${displayPrice}${priceUnit ? `/${priceUnit}` : ""}`

  return (
    <div className={styles.article}>
      <div className={styles.image}>
        <Table.CellImage sources={imageSources} />
      </div>
      <div className={styles.data}>
        <Typography title={name} component="h1" variant="body2" noWrap width={maxWidth}>
          {name}
        </Typography>
        <Typography
          title={brand}
          component="h3"
          variant="body2"
          noWrap
          width={maxWidth}
        >
          {brand}
        </Typography>
        <Typography title={priceText} variant="body2" noWrap width={maxWidth}>
          {priceText}
        </Typography>
        <Typography title={content} variant="body2" noWrap width={maxWidth}>
          {content}
        </Typography>
      </div>
    </div>
  )
}

Article.propTypes = {
  brand: PropTypes.string,
  content: PropTypes.string,
  imageSources: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  displayPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceUnit: PropTypes.string,
}

Article.defaultProps = {
  brand: "",
  content: "",
  imageSources: null,
  displayPrice: INFINITY_SYMBOL,
  priceUnit: null,
}

function AssortmentAnalysisArticleDataCell({ articles, dimension }) {
  const [open, setOpen] = useState("")
  const { t } = useTranslation()
  const { highlight } = useSetHighlight()

  const handleClose = () => {
    setOpen("")
  }

  return (
    <ul className={styles.items}>
      {articles.map((article) => {
        const {
          attributes,
          regularSuPrice: price,
          regularItemPrice: itemPrice,
          regularUnitPrice: unitPrice,
          multipackSizes: multipacks,
          unit,
          unsure,
          unvalidated,
        } = article
        const brand = attributes.find((a) => a.name === "brand")?.value
        const format = attributes.find((a) => a.name === "format")?.value
        const formatUnit = attributes.find((a) => a.name === "format_unit")?.value
        const image = attributes.find((a) => a.name === "image")?.value
        const name = attributes.find((a) => a.name === "name")?.value

        const images = typeof image === "string" ? [image] : null
        const pricePerSu = price ? Number(price).toFixed(2) : INFINITY_SYMBOL
        const pricePerItem = itemPrice ? Number(itemPrice).toFixed(2) : INFINITY_SYMBOL
        const pricePerUnit = unitPrice ? Number(unitPrice).toFixed(2) : INFINITY_SYMBOL
        const multipackSize = multipacks ? multipacks.join(", ") : null
        const title = titleCase(name || "")

        const itemContent = format ? `${format}${formatUnit || unit || ""}` : null

        const key = `${article.shop}-${article.baseProductId}-${article.articleIds}`
        return (
          <li
            key={key}
            className={styles.item}
            data-testid="assortment-article-data-cell"
            data-testkey={key}
          >
            <Tooltip
              title={
                highlight && unvalidated && unsure
                  ? t("Contains unvalidated and unsure attributes")
                  : highlight && unvalidated
                    ? t("Contains unvalidated attributes")
                    : highlight && unsure
                      ? t("Contains unsure attributes")
                      : ""
              }
              placement="right-start"
            >
              <Badge
                component="div"
                color={
                  highlight && unvalidated
                    ? "error"
                    : highlight && unsure
                      ? "ochre"
                      : "none"
                }
                variant="dot"
                invisible={!highlight || (!unsure && !unvalidated)}
                sx={{ margin: 0, padding: 0, width: "100%" }}
              >
                <Box
                  className={styles["article-detail-trigger"]}
                  onClick={() => setOpen(key)}
                >
                  <Article
                    brand={brand}
                    content={itemContent}
                    imageSources={images}
                    name={title}
                    pricePerItem={pricePerItem}
                    displayPrice={
                      dimension === DIMENSION_PRICE ? pricePerItem : pricePerUnit
                    }
                    priceUnit={dimension === DIMENSION_UOM_PRICE ? unit : null}
                  />
                </Box>
              </Badge>
            </Tooltip>
            <Dialog
              fullWidth
              maxWidth="lg"
              onClose={handleClose}
              open={open === key}
              data-testid={`assortment-dialog-${key}`}
            >
              <DialogTitle
                sx={{ textTransform: "capitalize" }}
                data-testid={`assortment-dialog-title-${key}`}
              >
                {t(title)}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <ArticleDetail
                  attributes={article.attributes}
                  imageSources={images}
                  pricePerSu={pricePerSu}
                  pricePerItem={pricePerItem}
                  pricePerUnit={pricePerUnit}
                  multipackSizes={multipackSize}
                  unsureMultipackSizes={article.unsureMultipackSizes}
                  unit={unit}
                  shop={article.shop}
                  articleIds={article.articleIds}
                  baseProductId={article.baseProductId}
                  turnover={article.turnover}
                  rotation={article.rotation}
                  year={article.year}
                  sourceType={article.sourceType}
                  lastActiveOn={article.lastActiveOn}
                />
              </DialogContent>
            </Dialog>
          </li>
        )
      })}
    </ul>
  )
}

AssortmentAnalysisArticleDataCell.propTypes = {
  articles: PropTypes.arrayOf(ArticlePropType).isRequired,
}
AssortmentAnalysisArticleDataCell.defaultProps = {}

export { AssortmentAnalysisArticleDataCell }
