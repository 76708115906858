import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import useLocalStorage from "../../../hooks/use-local-storage"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_FILTER_SHOPS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ filterShops: boolean, setFilterShops: ({ filterShops }) => void}}
 *  filter or not the shops and a setter function
 */
export function useSetShopFilter() {
  const dispatch = useDispatch()
  const [filterShopsSettings, setFilterShopsSettings] = useLocalStorage(
    "assortment-analysis-settings-filter-shops",
    false,
  )

  const filterShops = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTER_SHOPS_KEY,
    ]),
  )

  function setFilterShops({ filterShops: newFilterShops }) {
    setFilterShopsSettings(newFilterShops)
    dispatch(Actions.setAssortmentAnalysisFilterShops({ filterShops: newFilterShops }))
  }

  return { filterShops, savedFilterShops: filterShopsSettings, setFilterShops }
}
